import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const routes = [{
  path: '/',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'home-page',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/HomePage.vue')
  }]
},{
  path: '/letter',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'letter-page',
    component: () => import(/* webpackChunkName: "letter-page" */ '@/pages/LetterPage.vue')
  }]
},{
  path: '/zoom',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'zoom-page',
    component: () => import(/* webpackChunkName: "zoom-page" */ '@/pages/ZoomPage.vue')
  }]
},{
  path: '/about',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'about-page',
    component: () => import(/* webpackChunkName: "about-page" */ '@/pages/AboutPage.vue')
  }]
},{
  path: '/history',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'history-page',
    component: () => import(/* webpackChunkName: "history-page" */ '@/pages/HistoryPage.vue')
  }]
}, {
  path: '/pastor',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'pastor-page',
    component: () => import(/* webpackChunkName: "history-page" */ '@/pages/PastorPage.vue')
  }]
},{
  path: '/services',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'service-page',
    component: () => import(/* webpackChunkName: "service-page" */ '@/pages/ServicePage.vue')
  }]
}, {
  path: '/calendar',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'calendar-page',
    component: () => import(/* webpackChunkName: "calendar-page" */ '@/pages/CalendarPage.vue')
  }]
}, {
  path: '/ministries',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'ministries-page',
    component: () => import(/* webpackChunkName: "ministries-page" */ '@/pages/MinistriesPage.vue')
  }]
},{
  path: '/bulletins',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'bulletin-page',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/BulletinPage.vue')
  }]
},{
  path: '/contact',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'contact-page',
    component: () => import(/* webpackChunkName: "contact-page" */ '@/pages/ContactPage.vue')
  }]
},{
  path: '/watch',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'watch-page',
    component: () => import(/* webpackChunkName: "watch-page" */ '@/pages/WatchPage.vue')
  }]
}, {
  path: '/terms',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'terms',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/TermsPage.vue')
  }]
}, {
  path: '/privacy',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/PrivacyPage.vue')
  }]
},{
  path: '/admin',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/SimpleLayout'),
  children: [{
    path: 'banners',
    name: 'banners-page',
    component: () => import(/* webpackChunkName: "banners-page" */ '@/pages/admin/BannersPage'),
    meta: {
      title: 'Banners'
    }
  },{
    path: 'bulletin',
    name: 'bulletin-page',
    component: () => import(/* webpackChunkName: "bulletin-page" */ '@/pages/admin/BulletinPage'),
    meta: {
      title: 'Bulletin'
    }
  },{
    path: '/',     
    component: () => import(/* webpackChunkName: "bulletin-page" */ '@/pages/admin/AdminPage')
  }]
},  {
  path: '*',
  component: () => import(/* webpackChunkName: "layout-error" */ '@/layouts/ErrorLayout.vue'),
  children: [{
    path: '',
    name: 'error',
    component: () => import(/* webpackChunkName: "error" */ '@/pages/error/NotFoundPage.vue')
  }]
}]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes
})

/**
 * Before each route update
 */
router.beforeEach((to, from, next) => {
  return next()
})

/**
 * After each route update
 */
router.afterEach((to, from) => {
})

export default router
