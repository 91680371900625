import Vuetify from '../../plugins/vuetify'

export default {
  /**
   * Theme and Layout
   */
  setGlobalTheme: (state, theme) => {
    Vuetify.framework.theme.dark = theme === 'dark'
    state.globalTheme = theme
  },
  setRTL: (state, isRTL) => {
    Vuetify.framework.rtl = isRTL
    state.isRTL = isRTL
  },
  storeUserDetails: (state, userDetails) => {
    state.userDetails = userDetails
  },
  storeCurrentBulletin: (state, bulletinUrl) => {
    state.bulletinUrl = bulletinUrl
  }
}
