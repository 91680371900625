import configs from '../../configs'
import mutations from './mutations'
import axios from 'axios'

const { product, theme  } = configs
const { isRTL, globalTheme } = theme

// state initial values
const state = {
  product,

  // app theme dark or light
  globalTheme,

  // themes and layout configurations
  isRTL,

  userDetails: null,

  bulletinUrl: null
}

// getters
const getters = {
  isAuthenticated: (state, getters, rootState) => {
    return state.userDetails !== undefined && state.userDetails !== null
  },

  canUploadBanners: (state, getters, rootState) => {
    return state.userDetails !== undefined && state.userDetails !== null && state.userDetails.canUploadBanners
  },

  canUploadBulletin: (state, getters, rootState) => {
    return state.userDetails !== undefined && state.userDetails !== null && state.userDetails.canUploadBulletin
  },

  bulletinUrl: (state, getters, rootState) => {
    return state.bulletinUrl
  }
}

// actions
const actions = {
  setUserDetails ({ commit, state }, userDetails) {
    commit('storeUserDetails', userDetails)
  },
  setBulletin ({ commit, state }, bulletinUrl) {
    commit('storeCurrentBulletin', bulletinUrl)
  },
  retrieveBulletin ({ commit, state }) {
    axios ({
      method: 'get',
      url: `${process.env.VUE_APP_BANNERS_API_URL}bulletin/${process.env.VUE_APP_BANNERS_API_ENTITY}`,
      headers: {
        'Ocp-Apim-Subscription-Key': process.env.VUE_APP_BANNERS_API_KEY
      }
    })
      .then((response) => {
        commit('storeCurrentBulletin', response.data[0].bulletin.linkUrl)
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
